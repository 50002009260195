<template>
  <h1>No Permission</h1>
</template>
<script>
export default {
  name: 'NoPermission',
  data() {
    return {};
  },
};
</script>
